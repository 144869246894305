<template>
  <div
    v-if="showLine"
    class="absolute top-0 h-full scrubber bg-red-500 z-10 cursor-pointer"
    :style="lineStyle"
  />
</template>
<script>
export default {
  name: 'TimelineScrubberLine',

  props: ['currentProgresspc', 'scale'],

  computed: {

    showLine () {
      return (this.currentProgresspc !== false)
    },

    lineStyle () {
      return 'left : ' + (this.currentProgresspc * this.scale) + '%'
    }
  }
}
</script>
